<template>
    <v-card id="login" flat color="secondaryBackgroundColor">
        <v-card-subtitle
            class="ma-0 pa-0 pb-5 text-center font-weight-black font-size-28"
            >Login</v-card-subtitle
        >
        <v-form v-model="formData.status" ref="formData" class="text-center">
            <v-alert v-if="formData.error" type="error" dense outlined>{{
                formData.error
            }}</v-alert>
            <div class="text-left font-weight-bold grey--text text--darken-1">
                EMAIL ADDRESS
            </div>
            <v-text-field
                background-color="#FFFFFF"
                v-model="formData.email"
                label="Email Address"
                placeholder="Email Address"
                color="secondaryBackgroundColor"
                autocomplete="off"
                flat
                solo
                dense
                autofocus
                :rules="[$validate.rules.required, $validate.rules.email]"
                required
                @keyup.enter="signIn"
            />
            <div class="text-left font-weight-bold grey--text text--darken-1">
                PASSWORD
            </div>
            <v-text-field
                background-color="#FFFFFF"
                v-model="formData.password"
                label="Password"
                placeholder="Password"
                color="primaryTextColor"
                flat
                solo
                dense
                :rules="[$validate.rules.required]"
                required
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                @keyup.enter="signIn"
            />
            <v-btn
                depressed
                dark
                color="#389877"
                rounded
                :loading="formData.loading"
                @click="signIn"
                >Log In</v-btn
            >
            <br />
            <v-btn plain small class="mt-3 body-2 font-weight-normal">
                Forgot Password?
            </v-btn>
        </v-form>
    </v-card>
</template>

<script>
export default {
    name: "login",
    data() {
        return {
            showPassword: false,
            formData: {
                status: false,
                loading: false,
                error: "",
                email: "",
                password: "",
            },
        };
    },
    computed: {
        query() {
            return this.$route.query;
        },
    },
    methods: {
        signIn() {
            let formStatus = this.$refs.formData.validate();

            if (formStatus && this.formData.status && !this.formData.loading) {
                let { email, password } = this.formData;
                this.formData.error = "";
                this.formData.loading = true;

                this.$store
                    .dispatch("authentication/login", {
                        data: { email, password },
                    })
                    .then((response) => {
                        if (response.token) {
                            this.$cookies.setValue("token", response.token);
                            this.$api.main.setAuthorization(response.token); // Decode Base64
                        }
                        if (response.data.user) {
                            this.$cookies.setValue("user", response.data.user);
                        }

                        if (this.query.redirect) {
                            this.$router.push({ name: this.query.redirect });
                        } else {
                            this.$router.push({ name: "main-view" });
                        }

                        this.formData.loading = false;
                    })
                    .catch((error) => {
                        console.log(error.response);
                        this.formData.loading = false;
                        this.formData.error = error.response.data
                            ? error.response.data.message
                            : error.message;
                    });
            }
        },
    },
    mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#login {
}
</style>
